import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Breakdance";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>Το Βreakdance είναι υποκατηγορία του hip hop.</P>
        <P>
          Οι κινήσεις του γίνονται σε όρθια στάση (uprock moves) ή όταν ο
          χορευτής βρίσκεται χαμηλά στο έδαφος (downrock moves) και
          περιλαμβάνουν ακροβατικές κινήσεις με πολλές στροφές και πόζες.
          Αποτελεί μια από τις πιο ελεύθερες μορφές χορού, προσφέροντας τη
          δυνατότητα αυτοσχεδιασμού σε εξαιρετικά μεγάλο βαθμό και δίνοντας έτσι
          την ευκαιρία στους breakers να αναπτύξουν το δικό τους χορευτικό στιλ.
        </P>
        <P>
          Η μυϊκή δύναμη, η ευλυγισία και ο συντονισμός είναι μερικά από τα
          απαραίτητα χαρακτηριστικά ενός χορευτή Βreakdance, γι' αυτό απαιτείται
          συνεχής εξάσκηση κι αρκετός χρόνος για να τελειοποιήσει κάποιος τις
          ικανότητές του.
        </P>
      </Section.Main>
    </Layout>
  );
}
